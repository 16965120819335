import env from '../utils/env';

export const BOLT_REGISTER_URL = `${env.NEXT_PUBLIC_BOLT_DOMAIN}/register`;

export const BOLT_LOGIN_URL = `${env.NEXT_PUBLIC_BOLT_DOMAIN}`;

export const BOLT_REZEN_URL = `${env.NEXT_PUBLIC_BOLT_DOMAIN}/welcome-to-rezen/mobile-app`;

export const BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL =
  'https://support.therealbrokerage.com/hc/en-us/article_attachments/31123934879895';

export const BOLT_CUSTOM_ASSET_FOR_TEXAS_CPN_PDF_URL =
  'https://bolt-custom-assets.therealbrokerage.com/texas-real-estate-commission/cpn.pdf';
